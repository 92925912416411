// src/components/Skill.js
import React from 'react';
import { useInView } from 'react-intersection-observer';

const Skill = ({ name, level, icon }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 });

  return (
    <li className="skill">
      <div className="skill-icon">{icon}</div>
      <div className="skill-name">{name}</div>
      <div className="progress-bar">
        <div
          ref={ref}
          className="progress"
          style={{
            width: inView ? level : '0%',
            transition: 'width 1s ease-in-out'
          }}
        ></div>
      </div>
    </li>
  );
};

export default Skill;
