// src/components/Projects.js
import React, { useState, useEffect } from 'react';
import './Project.scss';
import project1 from '../assets/project1.png';
import project2 from '../assets/ecom.png';
import project3 from '../assets/type.png';

import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/dist/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);


const projects = [
  {
    id: 1,
    title: 'Chat Applicatie met React en Firebase',
    description: 'Ik heb een chat applicatie gemaakt met React en Firebase. Het is een leuke manier om te oefenen met React en Firebase!',
    imageUrl: project1,
    link: 'https://chatappnew-98d0b.web.app/'
  },
  {
    id: 2,
    title: 'Ecommerce Website met PHP en MySQL met werkend betaalsysteem',
    description: 'Ik heb een ecommerce website gemaakt met PHP en MySQL. Het heeft een werkend betaalsysteem en is een leuke manier om te oefenen met PHP en MySQL!',
    imageUrl: project2,
    link: 'https://jljnootenboom.com/../ecom/'
  },
  {
    id: 3,
    title: 'Een social media website met PHP en MySQL',
    description: 'Dit project had ik in mijn eerste jaar gemaakt. Het is een social media website met PHP en MySQL. Toen ik dit maakte programeerde ik nog niet eens een half jaar!',
    imageUrl: project3,
    link: 'https://jljnootenboom.com/typechat/Login/Login.php'
  },
];

const Projects = () => {
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % projects.length);
    }, 10000); // 10 seconden
    return () => clearInterval(interval);
  }, []);

  const nextProject = () => {
    setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % projects.length);
  };

  const prevProject = () => {
    setCurrentProjectIndex((prevIndex) => (prevIndex - 1 + projects.length) % projects.length);
  };

  return (
    <div className="projects" id='projects'>
      <div className="intro">
        <h2><span>Mijn</span> Projecten</h2>
        <p>
          Ik ben trots op de projecten die ik heb gemaakt, en zou ze heel graag aan u laten zien!<br />
          <span> Bekijk ze hieronder!</span>
        </p>
      </div>
      <div className="projects-carousel">
        <button className="prev" onClick={prevProject}>&#10094;</button>
        {projects.map((project, index) => (
          <div 
            key={project.id} 
            className={`project ${index === currentProjectIndex ? 'active' : ''}`}
          >
            <img src={project.imageUrl} alt={project.title} className="project-image" />
            <div className="project-info">
              <h3>{project.title}</h3>
              <p>{project.description}</p>
              <a 
                href={project.link} 
                target='_blank'
                rel="noopener noreferrer"
                className={index === currentProjectIndex ? '' : 'disabled'}
              >
                Bekijk Project
              </a>
            </div>
          </div>
        ))}
        <button className="next" onClick={nextProject}>&#10095;</button>
      </div>
    </div>
  );
};

export default Projects;
