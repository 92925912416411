// src/components/Home.js
import React from 'react';
import './Home.scss';
import Typewriter from './Typewriter'; // Import the Typewriter component
import profilePic from '../assets/picture.png'; // Import a stock image here
import circle from '../assets/shapes/circle.svg';
import cross from '../assets/shapes/cross.svg';
import diamond from '../assets/shapes/diamond.svg';
import rectangle from '../assets/shapes/rectangle.svg';

import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/dist/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);


const Home = () => {
  return (
    <section id="home" className="home">
      <div className="intro">
      <div className="profile-pic">
        <img src={profilePic} alt="Profile" className='foto' />
      </div>
        <div className='rest'>
        <h1><span>Hoi,</span> Mijn naam is <br></br> <span>J</span>ustin <span>N</span>ootenboom<span>!</span></h1>
        <Typewriter />
        <img src={rectangle} alt="rectangle" className="rectangle" />
        <br></br>
        <img src={circle} alt="circle" className="shape circle" />
        <img src={cross} alt="cross" className="shape cross" />
        <img src={diamond} alt="diamond" className="shape diamond" />
        </div>
      </div>
    </section>
  );
};

export default Home;
