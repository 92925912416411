// src/components/Typewriter.js
import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';
import './Typewriter.scss';

gsap.registerPlugin(TextPlugin);

const Typewriter = () => {
  const textRef = useRef(null);

  useEffect(() => {
    const words = ['Web Developer', 'Software Developer', 'Full Stack Developer'];
    let currentWordIndex = 0;
    let currentLetterIndex = 0;
    let currentText = '';

    const animateText = () => {
      if (currentLetterIndex < words[currentWordIndex].length) {
        currentText += words[currentWordIndex][currentLetterIndex];
        gsap.to(textRef.current, {
          duration: 0.2, // Adjust duration per letter
          text: currentText,
          ease: 'power1.inOut',
          onComplete: animateText,
        });
        currentLetterIndex++;
      } else {
        setTimeout(() => {
          currentText = '';
          currentLetterIndex = 0;
          currentWordIndex = (currentWordIndex + 1) % words.length;
          animateText();
        }, 3000); // Pause before starting next word
      }
    };

    animateText();
  }, []);

  return (
    <h2 className="typewriter-text">
      En ik ben een: <br></br> <span ref={textRef}>Web Developer</span>
    </h2>
  );
};

export default Typewriter;
