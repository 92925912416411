// src/components/About.js
import React from 'react';
import './About.scss';
import { FaHtml5, FaCss3Alt, FaJs, FaReact, FaNodeJs, FaPhp, FaPython, FaBootstrap } from 'react-icons/fa';
import Skill from './Skill';

import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/dist/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);


const About = () => {
  const skills = [
    { name: 'HTML', level: '90%', icon: <FaHtml5 /> },
    { name: 'CSS', level: '80%', icon: <FaCss3Alt /> },
    { name: 'JavaScript', level: '70%', icon: <FaJs /> },
    { name: 'React', level: '50%', icon: <FaReact /> },
    { name: 'Node.js', level: '50%', icon: <FaNodeJs /> },
    { name: 'PHP', level: '80%', icon: <FaPhp /> },
    { name: 'Python', level: '50%', icon: <FaPython /> },
    { name: 'Bootstrap', level: '65%', icon: <FaBootstrap /> },
  ];

  return (
    <div className="about" id='about'>
      <div className="intro">
        <h2><span>Hoi,</span> mijn naam is <span>J</span>ustin <span>N</span>ootenboom!</h2>
        <p>
          <span>E</span>n ik ben een web developer en software developer uit Nederland!<br />
          Ik studeer nu op het Grafisch Lyceum Rotterdam en ben 18 jaar oud!<br />
          Ik hou van auto's en mijn favoriete drankje is Monster!<br /><br />
          Als je meer over mij wil weten, stuur me dan <a className='link' href='#contact'>hier</a> een mailtje!
        </p>
      </div>
      <div className="skills">
        <h3>Mijn Vaardigheden</h3>
        <ul className="skill-list">
          {skills.map((skill, index) => (
            <Skill key={index} name={skill.name} level={skill.level} icon={skill.icon} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default About;
