import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './Header.scss';
import logo from '../assets/logo.png';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      gsap.to(window, {
        scrollTo: element,
        duration: 1,
        ease: 'power3.inOut',
      });
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (window.innerWidth <= 768) {
      if (isOpen) {
        gsap.to(navRef.current, {
          duration: 0.5,
          y: 0,
          opacity: 1,
          display: 'flex',
          ease: 'power3.inOut',
        });
      } else {
        gsap.to(navRef.current, {
          duration: 0.5,
          y: -50,
          opacity: 0,
          display: 'none',
          ease: 'power3.inOut',
        });
      }
    } else {
      gsap.set(navRef.current, {
        y: 0,
        opacity: 1,
        display: 'flex',
      });
    }
  }, [isOpen]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        gsap.set(navRef.current, {
          y: 0,
          opacity: 1,
          display: 'flex',
        });
      } else if (!isOpen) {
        gsap.set(navRef.current, {
          y: -50,
          opacity: 0,
          display: 'none',
        });
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isOpen]);

  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <nav ref={navRef} className={`nav-links ${isOpen ? 'open' : ''}`}>
        <a href="#home" onClick={() => scrollToSection('home')}>Home</a>
        <a href="#about" onClick={() => scrollToSection('about')}>About Me</a>
        <a href="#projects" onClick={() => scrollToSection('projects')}>Projects</a>
        <a href="#contact" onClick={() => scrollToSection('contact')}>Contact</a>
      </nav>
      <div className={`burger ${isOpen ? 'toggle' : ''}`} onClick={toggleMenu}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </header>
  );
};

export default Header;
