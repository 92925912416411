// src/components/Contact.js
import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.scss';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/dist/ScrollToPlugin';
import img from '../assets/home-main.svg';

gsap.registerPlugin(ScrollToPlugin);


const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    emailjs.sendForm('service_rnc6mtp', 'template_kt3a8v8', e.target, 'dUcvaR85EYzW_so6v')
      .then((result) => {
          console.log('Success:', result.text);
          // Optioneel: reset de formulierdata en toon een succesbericht
          if (result.text === 'OK') {
            alert('Je bericht is verstuurd! We nemen zo snel mogelijk contact met je op.');
          }
          setFormData({
            name: '',
            email: '',
            message: '',
          });
      }, (error) => {
          console.log('Error:', error.text);
          // Optioneel: toon een foutbericht
      });
  };

  return (

    <div className="contact" id='contact'>
      <div className="contact-info">
        <h3>Neem Contact Op</h3>
        <p>Als je vragen hebt, neem gerust contact met ons op!</p>
        <img src={img} alt='hoi' className='hoi'></img>
      </div>
      <form className="contact-form" onSubmit={handleSubmit}>
        <p className='stuptekst'><strong>Stop</strong> hier all uw info in en ik zal zo snel mogelijk probeeren te antwoorden!</p>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Je Naam"
          required
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Je E-mail"
          required
        />
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Je Bericht"
          rows="4"
          required
        />
        <button type="submit">Verstuur Bericht</button>
      </form>
    </div>
  );
};

export default Contact;
